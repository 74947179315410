import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';

document.addEventListener('DOMContentLoaded', function () {
  const bodyData = document.querySelector('body').dataset;

  if (['staging', 'production'].includes(bodyData.environment)) {
    Sentry.init({
      dsn: bodyData['sentryDsn'],
      environment: bodyData['environment'],
      release: 'intercede ' + bodyData['version'],
      integrations: [new BrowserTracing()],
      tracesSampleRate: 0.1,
      ignoredErrors: ['401 (Unauthorized)']
    });

    Sentry.configureScope((scope) => {
      scope.setUser({ id: bodyData['userId'] });
    });
  }
});
